import {
  createConfiguration,
  ServerConfiguration,
} from '@dronetag/dronetag-sdk-live'
import { Environment } from './rootState'

const liveServiceEnvironmentBaseUrls: { [env: string]: string } = {
  staging: 'https://live.staging.dronetag.app',
  production: 'https://live.dronetag.app',
  local: 'http://localhost:8000',
}

const baseServer = new ServerConfiguration('{baseUrl}/api/v2', {})

export const dronetagSdkConfig = createConfiguration({
  baseServer,
})

export function switchEnvironment(env: Environment) {
  const baseUrl = liveServiceEnvironmentBaseUrls[env]
  localStorage.setItem('env', env)
  console.log(`Environment set to ${env} (${baseUrl})`)
  baseServer.setVariables({
    baseUrl,
  })
}

console.log('Configured SDK')
