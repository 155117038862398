import Location from './types/location'
import Mission from './types/mission'

// TODO: deduplicate
export const METERS_PER_DD = 111120 // plusminusautobus™

export function getNextTarget(mission: Mission): Location {
  const distance = Math.floor(Math.random() * mission.radius)
  const distanceDegrees = distance / METERS_PER_DD

  return {
    latitude: mission.takeoff.latitude + (Math.random() - 0.5) * 2 * distanceDegrees,
    longitude: mission.takeoff.longitude + (Math.random() - 0.5) * 2 * distanceDegrees,
  }
}
