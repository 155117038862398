import { ChangeEvent, useContext, useState } from 'react'
import {
  Typography,
  Grid,
  Slider,
  TextField,
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material'
import { StateContext } from '../rootState'
import { Close } from '@mui/icons-material'

interface ConfigurationDialogProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ConfigurationDialog({
  open,
  setOpen,
}: ConfigurationDialogProps) {
  const [rootState, dispatch] = useContext(StateContext)
  const [interval, setInterval] = useState<{
    [name: string]: number
  }>({
    simulation: rootState.reporting.simulationInterval,
    status: rootState.reporting.statusInterval,
    telemetry: rootState.reporting.telemetryInterval,
  })

  const [ctrlType, setCtrlType] = useState<{
    type: string
    aggregation: number
  }>({
    type: 'default',
    aggregation: 1,
  })

  const changeIntervalValue = (newValue: number, id?: string) => {
    if (!id) return undefined
    setInterval({ ...interval, [id]: newValue })
    if (id === 'simulation' && interval.telemetry < interval.simulation) {
      changeIntervalValue(interval.simulation, 'telemetry')
    }
  }

  const handleBoundIntervalChange =
    (id: string) => (_: Event, newValue: number | number[]) => {
      changeIntervalValue(Array.isArray(newValue) ? newValue[0] : newValue, id)
    }

  const handleEventIntervalChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event?.target?.value) return undefined
    changeIntervalValue(parseInt(event.target.value), event.target.id)
  }

  const handleApply = () => {
    dispatch({
      type: 'update_reporting',
      config: {
        simulationInterval: interval['simulation'],
        telemetryInterval: interval['telemetry'],
        statusInterval: interval['status'],
        type: ctrlType.type === 'packs' ? 'packs' : 'default',
        aggregation: ctrlType.aggregation,
      },
    })
  }

  return (
    <Dialog open={open} sx={{ padding: '1rem' }} fullWidth>
      <DialogContent>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '1rem',
          }}
        >
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{ flexBasis: 'full', width: '100%' }}
          >
            Mocker Configuration
          </Typography>
          <Button
            variant="text"
            size="small"
            onClick={() => setOpen(false)}
            sx={{ minWidth: 0 }}
          >
            <Close />
          </Button>
        </Box>
        <FormLabel>Simulation speed</FormLabel>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignItems: 'center',
            m: '0.5rem 0',
          }}
        >
          <TextField
            label="Sim. interval (ms)"
            size="small"
            value={interval.simulation}
            type="number"
            id="simulation"
            onChange={handleEventIntervalChange}
            sx={{ width: '25%' }}
          />
          <Slider
            size="medium"
            value={interval.simulation}
            valueLabelDisplay="auto"
            aria-label="Always visible"
            onChange={handleBoundIntervalChange('simulation')} // For better performance before commit to change saves to state only
            marks={[
              { value: 50, label: '50ms' },
              { value: 500, label: '500ms' },
              { value: 2000, label: '2s' },
            ]}
            min={50}
            max={2000}
            step={50}
            sx={{ width: '70%' }}
          />
        </Grid>
        <FormLabel>Reporting speed</FormLabel>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignItems: 'center',
            m: '0.5rem 0',
          }}
        >
          <TextField
            label="Telemetry interval (ms)"
            size="small"
            value={interval.telemetry}
            type="number"
            id="telemetry"
            onChange={handleEventIntervalChange}
            sx={{ width: '25%' }}
          />
          <Slider
            size="medium"
            value={interval.telemetry}
            valueLabelDisplay="auto"
            aria-label="Always visible"
            onChange={handleBoundIntervalChange('telemetry')} // For better performance before commit to change saves to state only
            marks={[
              ...(interval.simulation < 1500
                ? [
                    {
                      value: interval.simulation,
                      label: `${interval.simulation}ms`,
                    },
                  ]
                : []),
              { value: 2000, label: '2s' },
              { value: 5000, label: '5s' },
            ]}
            min={interval.simulation}
            max={5000}
            step={50}
            sx={{ width: '70%' }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: '1rem',
          }}
        >
          <TextField
            label="Status interval (ms)"
            size="small"
            id="status"
            value={interval.status}
            type="number"
            onChange={handleEventIntervalChange}
            sx={{ width: '25%' }}
          />
          <Slider
            size="medium"
            value={interval.status}
            valueLabelDisplay="auto"
            aria-label="Always visible"
            onChange={handleBoundIntervalChange('status')} // For better performance before apply to change saves to state only
            marks={[
              { value: 500, label: '500ms' },
              { value: 15000, label: '15s' },
              { value: 25000, label: '25s' },
            ]}
            min={500}
            max={25000}
            step={50}
            sx={{ width: '70%' }}
          />
        </Grid>
        <Box>
          <FormLabel id="radio-reporting-type">Reporting type</FormLabel>
        </Box>
        <Box>
          <FormControl>
            <RadioGroup defaultValue="default" name="reporting-type" row
                value={ctrlType.type}
                onChange={(ev) =>
                  setCtrlType({
                    type: (ev.target as HTMLInputElement).value,
                    aggregation: ctrlType.aggregation,
                  })
                }>
              <FormControlLabel
                aria-labelledby="radio-reporting-type"
                value="default"
                control={<Radio />}
                label="Via Default API"
              />
              <FormControlLabel
                value="packs"
                control={<Radio />}
                label="Via Update Packs"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <InputLabel id="pack-agg">Packs telemetry aggregation</InputLabel>
            <Select
              labelId="pack-agg"
              id="pack-agg"
              label="Packs telemetry aggregation"
              value={ctrlType.aggregation}
              onChange={(ev) =>
                setCtrlType({
                  aggregation: parseInt(ev.target.value.toString()),
                  type: ctrlType.type,
                })
              }
              size="small"
              sx={{ minWidth: 200 }}
              disabled={ctrlType.type !== 'packs'}
            >
              <MenuItem value={1}>None</MenuItem>
              <MenuItem value={2}>2 tele per msg</MenuItem>
              <MenuItem value={3}>3 tele per msg</MenuItem>
              <MenuItem value={4}>4 tele per msg</MenuItem>
              <MenuItem value={8}>8 tele per msg</MenuItem>
              <MenuItem value={12}>12 tele per msg</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Button
          variant="contained"
          color="success"
          sx={{ float: 'right', marginTop: '1rem' }}
          size="large"
          onClick={() => {
            handleApply()
            setOpen(false)
          }}
        >
          Apply
        </Button>
      </DialogContent>
    </Dialog>
  )
}
