import { Box, Button, Grid, Slider, TextField, Typography } from '@mui/material'
import React from 'react'
import MockedObject, { MockMapFunction } from '../types/mockedObject'

interface MockerConfigurationProps {
  mock: MockedObject
  onChange: (uasId: string, mapper: MockMapFunction) => void
}

const marks = [
  {
    value: 10,
    label: '10 m/s',
  },
  {
    value: 50,
    label: '50 m/s',
  },
  {
    value: 90,
    label: '90 m/s',
  },
]

const ariaLabel = (val: number) => `${val} m/s`

export default function MockerConfiguration({
  mock,
  onChange,
}: MockerConfigurationProps) {
  const [params, setParams] = React.useState<{
    speed: number
    altitude: number | undefined
    satellites: number
    battery: number
    rsrp: number
  }>({
    speed: mock.mission.speed,
    altitude: mock.state.location.altitude,
    satellites: mock.state.satellites,
    battery: mock.state.battery,
    rsrp: mock.state.rsrp,
  })

  const handleSpeedChange = (_: any, newValue: number | number[]) => {
    setParams({
      ...params,
      speed: Array.isArray(newValue) ? newValue[0] : newValue,
    })
  }

  const handleStatePropChange =
    (
      name:
        | keyof MockedObject['state']
        | keyof MockedObject['state']['location']
    ) =>
    (ev: React.FocusEvent<HTMLInputElement>) => {
      setParams({
        ...params,
        [name]:
          ev.target.type === 'number'
            ? ev.target.valueAsNumber
            : ev.target.value,
      })
    }

  const handleApply = () => {
    onChange(mock.uasId, (mock) => ({
      ...mock,
      mission: {
        ...mock.mission,
        speed: params.speed,
      },
      state: {
        ...mock.state,
        battery: params.battery,
        rsrp: params.rsrp,
        satellites: params.satellites,
        location: {
          ...mock.state.location,
          altitude: params.altitude,
        },
      },
    }))
  }

  return (
    <Box sx={{ mt: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ mx: 0.5 }}>
          <Typography variant="caption" gutterBottom>
            Target speed
          </Typography>
          <Slider
            size="small"
            value={params.speed}
            valueLabelDisplay="auto"
            getAriaValueText={ariaLabel}
            aria-label="Always visible"
            marks={marks}
            onChange={handleSpeedChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Altitude (m)"
            size="small"
            defaultValue={params.altitude}
            type="number"
            onBlur={handleStatePropChange('altitude')}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Satellites"
            size="small"
            defaultValue={params.satellites}
            type="number"
            fullWidth
            onBlur={handleStatePropChange('satellites')}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Battery (V)"
            size="small"
            defaultValue={params.battery}
            type="number"
            fullWidth
            onBlur={handleStatePropChange('battery')}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="RSRP (dBm)"
            size="small"
            defaultValue={params.rsrp}
            type="number"
            fullWidth
            onBlur={handleStatePropChange('rsrp')}
          />
        </Grid>
        <Button
          variant="contained"
          size="large"
          sx={{ m: '1rem auto 0rem' }}
          onClick={handleApply}
        >
          Apply
        </Button>
      </Grid>
    </Box>
  )
}
