import { useEffect, useReducer } from 'react'
import { styled, ThemeProvider } from '@mui/material/styles'
import { Container, Grid, Paper, Typography } from '@mui/material'
import MainAppBar from './components/MainAppBar'
import theme from './theme'
import Map from './components/Map'
import Console from './components/Console'
import MockerListToolbar from './components/MockerListToolbar'
import MockedObject, { MockMapFunction } from './types/mockedObject'
import useInterval from './hooks/useInterval'
import { generateStatus, generateTelemetry, stepSimulation } from './simulator'
import { MockerItemControls, MockerList } from './components/MockerList'
import {
  beginSession,
  endSession,
  reportTelemetry,
  reportStatus,
  reportPack,
} from './reporter'
import { MissionRequest } from './types/mission'
import { defaultState, reducer, StateContext } from './rootState'
import { switchEnvironment } from './sdkConfiguration'
import { addLastUsedDevice } from './localConfig'
import MockerControls from './components/MockerControls'
import projectPackage from '../package.json'
import { Telemetry } from '@dronetag/dronetag-sdk-live'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))

function App() {
  // App's root (main) state
  const [state, dispatch] = useReducer(reducer, defaultState)

  const singleStep = () => dispatch({ type: 'map_mocks', fn: stepSimulation })
  const togglePause = () => dispatch({ type: 'toggle_running' })

  async function handleNewMock(request: MissionRequest) {
    const session = await beginSession(request.uasId, request.mission.takeoff)
    dispatch({ type: 'create_mock', request, session: session })
    addLastUsedDevice(request.uasId)
  }

  async function handleLand(mock: MockedObject) {
    if (mock.session) {
      dispatch({
        type: 'update_mock',
        id: mock.uasId,
        fn: (mock) => ({ ...mock, isActive: false, isBusy: true }),
      })
      await endSession(mock.session)
    }
    dispatch({ type: 'remove_mock', id: mock.uasId })
  }

  async function handleLandAll() {
    Object.values(state.mocks).forEach(handleLand)
  }

  function handleKillAll() {
    dispatch({ type: 'clear_mocks' })
  }

  function storeTelemetryToPack(mock: MockedObject, telemetry: Telemetry) {
    dispatch({ type: 'store_telemetry', id: mock.uasId, telemetry })
    const totalStored = mock.storedTelemetry?.length ?? 0 + 1
    console.log(
      `Storing telemetry to be later sent in pack for ${mock.uasId} (${totalStored}/${state.reporting.aggregation})`
    )

    if (totalStored >= state.reporting.aggregation) {
      reportPack(state.mocks[mock.uasId])
      dispatch({ type: 'clear_stored_telemetry', id: mock.uasId })
    }
  }

  useEffect(() => {
    switchEnvironment(state.environment)
  }, [state.environment])

  const mockerControls: MockerItemControls = {
    onRetry(uasId) {
      handleNewMock({
        mission: {
          type: 'random',
          takeoff: {
            latitude: state?.defaultTakeoff?.latitude ?? 50.1,
            longitude: state?.defaultTakeoff?.longitude ?? 14.4,
          },
          speed: 20,
          radius: 200,
        },
        uasId,
      })
    },
    onPause(uasId) {
      dispatch({
        type: 'update_mock',
        id: uasId,
        fn: (mock) => ({
          ...mock,
          isActive: !mock.isActive,
        }),
      })
    },
    onLand(uasId) {
      const mock = state.mocks[uasId]
      if (!mock) return
      handleLand(mock)
    },
    onKill(uasId) {
      dispatch({ type: 'remove_mock', id: uasId })
    },
    onConfigurationChange(uasId, fn: MockMapFunction) {
      dispatch({ type: 'update_mock', id: uasId, fn })
    },
  }

  // Step simulation
  useInterval(() => {
    if (state.isRunning) singleStep()
  }, state.reporting.simulationInterval)

  // Report telemetry
  useInterval(() => {
    if (state.isRunning) {
      for (let mock of Object.values(state.mocks)) {
        if (!mock.isActive) continue
        const telemetry = generateTelemetry(mock)

        if (state.reporting.type === 'packs') {
          storeTelemetryToPack(mock, telemetry)
        } else {
          reportTelemetry(mock, telemetry)
        }

        dispatch({ type: 'increase_sent_telemetry', id: mock.uasId })
      }
    }
  }, state.reporting.telemetryInterval)

  // Report status messages
  useInterval(() => {
    if (state.isRunning) {
      for (let mock of Object.values(state.mocks)) {
        if (!mock.isActive || state.reporting.type === 'packs') continue
        const status = generateStatus(mock)
        reportStatus(mock, status)
        dispatch({ type: 'increase_sent_status', id: mock.uasId })
      }
    }
  }, state.reporting.statusInterval)

  return (
    <ThemeProvider theme={theme}>
      <StateContext.Provider value={[state, dispatch]}>
        <div className="App">
          <header className="App-header">
            <MainAppBar
              isRunning={state.isRunning}
              onSingleStep={singleStep}
              onPauseToggle={togglePause}
            />
          </header>
          <main>
            <Container maxWidth="xl" sx={{ py: 4 }}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={7}>
                  <Item elevation={2} sx={{ p: 0 }}>
                    <Map
                      origin={{ latitude: 50, longitude: 19 }}
                      activeMocks={Object.values(state.mocks)}
                      selectedMock={
                        state.selectedMockId !== null
                          ? state.mocks[state.selectedMockId]
                          : undefined
                      }
                      onMockSelected={(uasId) =>
                        dispatch({ type: 'select_mock', id: uasId })
                      }
                      onMockMoved={(uasId, lat, lon) =>
                        dispatch({
                          type: 'update_mock',
                          id: uasId,
                          fn: (mock) => ({
                            ...mock,
                            state: {
                              ...mock.state,
                              location: {
                                altitude: mock.state.location.altitude,
                                latitude: lat,
                                longitude: lon,
                              },
                            },
                          }),
                        })
                      }
                      onSelectionTargetMoved={(id, alt, lat, lon) =>
                        dispatch({
                          type: 'update_mock',
                          id: id,
                          fn: (mock) => ({
                            ...mock,
                            target: {
                              altitude: alt,
                              latitude: lat,
                              longitude: lon,
                            },
                          }),
                        })
                      }
                    />
                  </Item>
                </Grid>
                <Grid item xs={12} md={5}>
                  <MockerControls
                    requireApiKey={projectPackage.options.apiKeyRequired}
                  >
                    <MockerListToolbar
                      onNewMockStarted={handleNewMock}
                      onLandAll={handleLandAll}
                      onKillAll={handleKillAll}
                    />
                    <MockerList
                      mocks={Object.values(state.mocks)}
                      controls={mockerControls}
                    />
                  </MockerControls>
                </Grid>
                <Grid item xs={12}>
                  <Item
                    elevation={2}
                    sx={{ p: 0, background: '#485964', textAlign: 'left' }}
                  >
                    <Console />
                  </Item>
                </Grid>
              </Grid>
            </Container>
          </main>
          <footer>
            <Typography
              component="div"
              variant="caption"
              sx={{ textAlign: 'center', opacity: 0.5 }}
            >
              <p>
                Minimizing the browser window might pause all mocks. Check your
                browser &amp; power saving settings.
              </p>
              <p>
                <strong>{projectPackage.name}</strong> {projectPackage.version}
              </p>
            </Typography>
          </footer>
        </div>
      </StateContext.Provider>
    </ThemeProvider>
  )
}

export default App
