import { useContext, useEffect, useState } from 'react'
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Button,
  ButtonGroup,
  Typography,
  Divider,
} from '@mui/material'
import {
  MoreVert as MoreIcon,
  Add as AddIcon,
  ArrowDropDown,
} from '@mui/icons-material'
import { MissionRequest } from '../types/mission'
import NewMissionDialog from './NewMissionDialog'
import { StateContext } from '../rootState'
import { getLastUsedDevices } from '../localConfig'
import projectPackage from '../../package.json'
import IntervalToolbar from './ConfigurationDialog'

interface MockerListToolbarProps {
  onNewMockStarted: (request: MissionRequest) => void
  onLandAll: () => void
  onKillAll: () => void
}

export default function MockerListToolbar({
  onNewMockStarted,
  onLandAll,
  onKillAll,
}: MockerListToolbarProps) {
  const [configOpen, setConfigOpen] = useState<boolean>(false)
  const [deviceMenuEl, setDeviceMenuEl] = useState<null | HTMLElement>(null)
  const [moreMenuEl, setMoreMenuEl] = useState<null | HTMLElement>(null)
  const [newDialogOpen, setNewDialogOpen] = useState(false)
  const [availableDevices, setAvailableDevices] = useState<string[]>([])
  const [rootState] = useContext(StateContext)

  const isLimited = projectPackage.options.deviceListLimited

  function makePredefinedMission(uasId: string): MissionRequest {
    return {
      mission: {
        type: 'random',
        takeoff: rootState.defaultTakeoff,
        speed: 10,
        radius: 384,
      },
      uasId: uasId,
    }
  }

  useEffect(() => {
    if (isLimited) {
      console.log('Retrieving available devices...')
      fetch(
        'https://api.dronetag.app/v1/devices', // FIXME: fixed to prod
        { headers: { Authorization: `Api-Key ${rootState.apiKey}` } }
      )
        .then((data) => {
          return data.json()
        })
        .then((records) => {
          console.log(`${records.length} devices are available`)
          setAvailableDevices(records.map((r: any) => r['serial_number']))
        })
        .catch((err) => {
          console.error(
            `Failed to retrieve a list of available devices: ${err}`
          )
        })
    }

    setAvailableDevices(
      isLimited ? [] : getLastUsedDevices().reverse().slice(0, 10)
    )
  }, [isLimited, rootState.apiKey])

  return (
    <div>
      <IntervalToolbar open={configOpen} setOpen={setConfigOpen} />
      <Box sx={{ display: 'flex', mt: -1 }}>
        <NewMissionDialog
          open={newDialogOpen}
          onClose={() => setNewDialogOpen(false)}
          onStarted={(request) => {
            onNewMockStarted(request)
            setNewDialogOpen(false)
          }}
        />
        <Box sx={{ flexGrow: 1, textAlign: 'left' }}>
          <ButtonGroup
            variant="contained"
            aria-label="outlined primary button group"
            sx={{ my: 1 }}
          >
            {isLimited ? null : (
              <Button
                startIcon={<AddIcon />}
                onClick={() => setNewDialogOpen(true)}
              >
                Start new
              </Button>
            )}
            <Button
              color="primary"
              size="small"
              sx={{ pr: 2 }}
              onClick={(ev) => setDeviceMenuEl(ev.currentTarget)}
              disabled={availableDevices.length === 0}
            >
              {isLimited ? (
                <>
                  <AddIcon /> Select a device to mock
                </>
              ) : null}
              <ArrowDropDown />
            </Button>
          </ButtonGroup>
          <Menu
            id="menu-devices"
            anchorEl={deviceMenuEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(deviceMenuEl)}
            onClose={(_) => setDeviceMenuEl(null)}
          >
            {availableDevices.map((uasId) => (
              <MenuItem
                onClick={() => {
                  onNewMockStarted(makePredefinedMission(uasId))
                  setDeviceMenuEl(null)
                }}
                key={`predefined-${uasId}`}
              >
                {uasId} [Standard]
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <IconButton
          size="large"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={(ev) => setMoreMenuEl(ev.currentTarget)}
          color="inherit"
        >
          <MoreIcon />
        </IconButton>
        <Menu
          id="menu-moretoolbar"
          anchorEl={moreMenuEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(moreMenuEl)}
          onClose={() => setMoreMenuEl(null)}
        >
          <MenuItem
            onClick={() => {
              onLandAll()
              setMoreMenuEl(null)
            }}
          >
            Land all mocks
          </MenuItem>
          <MenuItem
            onClick={() => {
              onKillAll()
              setMoreMenuEl(null)
            }}
          >
            <Typography color="error">Kill all mocks</Typography>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              setConfigOpen(true)
              setMoreMenuEl(null)
            }}
          >
            <Typography>Configure</Typography>
          </MenuItem>
        </Menu>
      </Box>
    </div>
  )
}
