import { DeviceStatus, Telemetry, Velocity } from '@dronetag/dronetag-sdk-live'
import { calculatePressure } from './convertors'
import { getNextTarget } from './planner'
import Location from './types/location'
import MockedObject from './types/mockedObject'

// Simulation-related constants

const BATTERY_MIN = 2.5

export const intervalDefault = 1
export const flyspeedDefault = 5
export const metersPerDD = 111120 // plusminusautobus™
export const arrivedThresholdM = 50
export const batteryDischargeRate = 0.005
export const batteryDischargedThres = 3.5
export const simTAC = 6969
export const simCID = 420000

const randomChangeChoices = [0, 0, 0, -1, 1]
const getRandomChange = () =>
  randomChangeChoices[Math.floor(Math.random() * randomChangeChoices.length)]

function getDirectionToTarget(location: Location, target: Location) {
  const direction = [
    target.longitude - location.longitude,
    target.latitude - location.latitude,
  ]
  const distance = Math.sqrt(direction[0] ** 2 + direction[1] ** 2)
  return { direction, distance }
}

function determineVelocity(
  mock: MockedObject,
  interval: number
): { velocity: Velocity; distance: number } {
  if (!mock.target) return { velocity: { x: 0, y: 0, z: 0 }, distance: 0 }

  // Calculate target
  const { direction, distance } = getDirectionToTarget(
    mock.state.location,
    mock.target
  )
  const arrived = distance <= arrivedThresholdM / metersPerDD
  const normalizedDirection = arrived
    ? [0, 0]
    : [direction[0] / distance, direction[1] / distance]

  const flySpeed = mock.mission.speed * (Math.random() * (1 - 0.9) + 0.9)

  // Calculate current velocity
  return {
    velocity: {
      x:
        flySpeed * interval * normalizedDirection[0],
      y:
        flySpeed * interval * normalizedDirection[1],
      z: 0.01,
    },
    distance: distance,
  }
}

export function stepSimulation(mock: MockedObject): MockedObject {
  let { location, battery, satellites, rsrp } = mock.state
  const { target, mission } = mock

  // Skip when paused
  if (!mock.isActive) return mock

  const { velocity, distance } = determineVelocity(mock, intervalDefault)
  const arrived = distance <= arrivedThresholdM / metersPerDD

  // Return updated state
  return {
    ...mock,
    target: mission.radius > 0 && arrived ? getNextTarget(mission) : target,
    state: {
      ...mock.state,
      location: {
        latitude:
          location.latitude +
          (velocity.y / metersPerDD) * Math.cos(location.latitude),
        longitude: location.longitude + velocity.x / metersPerDD,
        altitude: (location.altitude ?? 0) + velocity.z,
      },
      velocity,
      battery: Math.max(
        BATTERY_MIN,
        battery - Math.random() * batteryDischargeRate
      ),
      satellites: Math.max(3, Math.min(satellites + getRandomChange(), 13)),
      rsrp: Math.max(-120, Math.min(rsrp + getRandomChange() * 2, -80)),
      pressure: calculatePressure(location.altitude ?? 0),
    },
  }
}

export function generateTelemetry(mock: MockedObject): Telemetry {
  return {
    time: new Date(Date.now()),
    location: mock.state.location,
    altitude: +(mock.state.location.altitude ?? 0).toFixed(2),
    pressure: +mock.state.pressure.toFixed(2),
    velocity: {
      x: +mock.state.velocity.x.toFixed(2),
      y: +mock.state.velocity.y.toFixed(2),
      z: +mock.state.velocity.z.toFixed(2),
    },
    horizontalAccuracy: 0,
    verticalAccuracy: 0,
    speedAccuracy: 0,
  }
}

export function generateStatus(mock: MockedObject): DeviceStatus {
  return {
    time: new Date(Date.now()),
    battery: +mock.state.battery.toFixed(2),
    cellid: mock.state.cellid,
    rsrp: +mock.state.rsrp.toFixed(1),
    satellites: mock.state.satellites,
  }
}
