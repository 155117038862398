const _lastUsedConfigKey = 'mocker.localconfig.lastused'

export function getLastUsedDevices(): string[] {
  const savedValue = localStorage.getItem(_lastUsedConfigKey)
  if (!savedValue) return []
  const deviceList = JSON.parse(savedValue)
  if (!Array.isArray(deviceList)) {
    throw TypeError('Last used devices in local storage are in invalid format')
  }
  return deviceList
}

export function addLastUsedDevice(uasId: string) {
  const lastUsed = new Set(getLastUsedDevices())
  
  // Re-sort if already present
  lastUsed.delete(uasId)
  lastUsed.add(uasId)

  localStorage.setItem(_lastUsedConfigKey, JSON.stringify(Array.from(lastUsed)))
}
