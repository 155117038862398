import {
  Box,
  Card,
  Button,
  Typography,
  Chip,
  CircularProgress,
} from '@mui/material'
import {
  Pause,
  PlayArrow,
  Close,
  KeyboardDoubleArrowDown,
  Circle,
  PinDrop,
  ArrowUpward,
  SatelliteAlt,
  Battery90,
  Refresh,
  Warning,
  Edit,
  KeyboardArrowUp,
  Send,
} from '@mui/icons-material'
import MockedObject, { MockMapFunction } from '../types/mockedObject'
import { useState } from 'react'
import MockerConfiguration from './MockerConfiguration'

export interface MockerItemControls {
  onPause: (uasId: string) => void
  onRetry: (uasId: string) => void
  onLand: (uasId: string) => void
  onKill: (uasId: string) => void
  onConfigurationChange: (uasId: string, fn: MockMapFunction) => void
}

interface MockerListItemProps {
  mock: MockedObject
  controls: MockerItemControls
}

export function MockerListItem(props: MockerListItemProps) {
  const {
    uasId,
    state: { location, satellites, battery },
  } = props.mock
  const { onPause, onRetry, onLand, onKill } = props.controls

  const [isEditing, setEditing] = useState(false)

  function renderStatusIcon() {
    if (props.mock.session == null) {
      return <Warning color="error" sx={{ fontSize: 18, pr: 1 }} />
    }
    if (props.mock.isBusy) {
      return <CircularProgress size={12} sx={{ px: 1, py: 0.5 }} />
    }
    return (
      <Circle color="success" sx={{ fontSize: 10, pl: 0.5, py: 0.5, pr: 1 }} />
    )
  }

  return (
    <Card
      elevation={2}
      sx={{ mb: 1, p: 2, opacity: props.mock.session === null ? 0.5 : 1 }}
    >
      <Box
        sx={{
          float: 'right',
          display: 'flex',
          gap: 1,
        }}
      >
        {props.mock.session === null ? (
          <Button
            variant="outlined"
            color="error"
            onClick={() => onRetry(uasId)}
          >
            <Refresh />
          </Button>
        ) : (
          <>
            <Button
              variant="outlined"
              color="info"
              onClick={() => onPause(uasId)}
            >
              {props.mock.isActive ? <Pause /> : <PlayArrow />}
            </Button>
            <Button
              variant="outlined"
              startIcon={<KeyboardDoubleArrowDown />}
              onClick={() => onLand(uasId)}
            >
              Land
            </Button>
          </>
        )}
        <Button
          variant="outlined"
          startIcon={<Close />}
          color="warning"
          onClick={() => onKill(uasId)}
        >
          Kill
        </Button>
      </Box>
      <Typography
        variant="h5"
        color={props.mock.session != null ? 'info' : 'error'}
        sx={{ mb: 2 }}
      >
        {renderStatusIcon()}
        {props.mock.uasId}
      </Typography>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Chip
          icon={<PinDrop />}
          label={`${location.latitude.toFixed(4)}, ${location.longitude.toFixed(
            4
          )}`}
          size="small"
          variant="outlined"
        />
        <Chip
          icon={<ArrowUpward />}
          label={`${location.altitude?.toFixed(1) ?? '-'}m`}
          size="small"
          variant="outlined"
        />
        <Chip
          icon={<SatelliteAlt />}
          label={satellites}
          size="small"
          variant="outlined"
        />
        <Chip
          icon={<Battery90 />}
          label={`${battery.toFixed(2)}V`}
          size="small"
          variant="outlined"
        />
        <Chip
          icon={<Send />}
          label={`${props.mock.stats.sentTelemetryMsgs} + ${props.mock.stats.sentStatusMsgs}`}
          size="small"
          variant="outlined"
        />
        <Chip
          icon={isEditing ? <KeyboardArrowUp /> : <Edit />}
          variant="outlined"
          size="small"
          color="primary"
          label={isEditing ? 'Collapse' : 'Edit'}
          onClick={() => setEditing(!isEditing)}
        />
      </Box>
      {isEditing ? (
        <MockerConfiguration
          mock={props.mock}
          onChange={props.controls.onConfigurationChange}
        />
      ) : null}
    </Card>
  )
}

export function MockerList({
  mocks,
  controls,
}: {
  mocks: MockedObject[]
  controls: MockerItemControls
}) {
  return (
    <Box sx={{ my: 2 }}>
      {mocks.map((mock) => (
        <MockerListItem mock={mock} key={mock.uasId} controls={controls} />
      ))}
    </Box>
  )
}
