import { Map as PigeonMap, Draggable } from 'pigeon-maps'
import { Box } from '@mui/material'
import { CenterFocusStrong, ArrowCircleUp } from '@mui/icons-material'
import MockedObject from '../types/mockedObject'

import AircraftIcon from './AircraftIcon'
import { useContext } from 'react'
import { StateContext } from '../rootState'

interface MapProps {
  origin: { latitude: number; longitude: number }
  activeMocks: MockedObject[]
  selectedMock?: MockedObject
  onMockSelected: (uasId: string | null) => void
  onMockMoved: (uasId: string, lat: number, lon: number) => void
  onSelectionTargetMoved: (
    id: string,
    alt: number,
    lat: number,
    lon: number
  ) => void
}

function cartoTiles(x: number, y: number, z: number, dpr?: number) {
  return `https://cartodb-basemaps-4.global.ssl.fastly.net/light_all/${z}/${x}/${y}${
    (dpr ?? 1) >= 2 ? '@2x' : ''
  }.png`
}

export default function Map(props: MapProps) {
  const [rootState, rootDispatch] = useContext(StateContext)

  return (
    <Box
      id="map"
      sx={{
        width: '100%',
        height: '450px',
        borderRadius: 1,
        overflow: 'hidden',
      }}
    >
      <PigeonMap
        defaultCenter={[50.1, 14.395]}
        defaultZoom={4}
        provider={cartoTiles}
        onClick={() => props.onMockSelected(null)}
        dprs={[1, 2]}
      >
        {/* Default takeoff icon */}
        <Draggable
          key="selection-target"
          anchor={[
            rootState.defaultTakeoff.latitude,
            rootState.defaultTakeoff.longitude,
          ]}
          offset={[12, 12]}
          onDragEnd={(point) =>
            rootDispatch({
              type: 'update_default_takeoff',
              location: {
                latitude: point[0],
                longitude: point[1],
              },
            })
          }
        >
          <ArrowCircleUp width={24} />
        </Draggable>

        {/* Active mocks icons */}
        {props.activeMocks.map((mock) => (
          <Draggable
            key={`mock-${mock.uasId}`}
            anchor={[
              mock.state.location.latitude,
              mock.state.location.longitude,
            ]}
            offset={[12, 24]}
            onDragEnd={(point) =>
              props.onMockMoved(mock.uasId, point[0], point[1])
            }
          >
            <div
              onClick={(ev) => {
                props.onMockSelected(mock.uasId)
                ev.stopPropagation()
              }}
            >
              <AircraftIcon
                uasId={mock.uasId}
                selected={mock === props.selectedMock}
              />
            </div>
          </Draggable>
        ))}

        {/* Selected mock target icon */}
        {props.selectedMock &&
        props.selectedMock.target &&
        props.selectedMock.uasId ? (
          <Draggable
            anchor={[
              props.selectedMock.target?.latitude,
              props.selectedMock.target?.longitude,
            ]}
            offset={[12, 12]}
            onDragEnd={(point) =>
              props.onSelectionTargetMoved(
                props.selectedMock?.uasId!,
                props.selectedMock?.state.location.altitude ?? 0,
                // ! - because props.selectedMock.uasId is in deps - ts was shouting nevertheless, so this had to be done
                point[0],
                point[1]
              )
            }
          >
            <CenterFocusStrong width={24} />
          </Draggable>
        ) : null}
      </PigeonMap>
    </Box>
  )
}
