import MockedObject from './types/mockedObject'
import { DeviceStatus, IngestApi, Telemetry } from '@dronetag/dronetag-sdk-live'
import { dronetagSdkConfig } from './sdkConfiguration'
import ReportingSession from './types/reportingSession'
import Location from './types/location'
import { calculatePressure } from './convertors'
import { generateStatus } from './simulator'

const ingestApi = new IngestApi(dronetagSdkConfig)

export async function beginSession(
  serialNumber: string,
  takeoffPoint: Location
): Promise<ReportingSession | null> {
  console.log(`Authenticating ${serialNumber}...`)
  const session = await ingestApi
    .authenticate({
      serialNumber,
      initialStateOnSuccess: 'ONLINE',
    })
    .catch((err) => {
      console.error(
        `Failed to authenticate the session: ${
          err?.detail?.msg ?? err.toString()
        }`
      )
    })

  if (!session) return null
  console.log(`Authenticated ${serialNumber} with token '${session.token}'.`)

  console.log('Creating flight...')
  try {
    const takeoffAltitude = takeoffPoint.altitude ?? 0
    const flight = await ingestApi.createFlight(
      {
        takeoffPoint,
        takeoffAltitude,
        takeoffPressure: calculatePressure(takeoffAltitude),
      },
      session.token
    )

    if (!flight) return null
    console.log(
      `Created new flight (CAT ${flight.flightCategory}, OPID ${flight.operatorId})`
    )
  } catch (err: any) {
    if (err.body?.detail?.[0]?.type === 'device-inflight') {
      console.log('The device already has active flight, continuing...')
      return { token: session.token }
    }

    console.error(`Failed to create a new flight: ${err}`)
    return null
  }

  return { token: session.token }
}

export async function endSession(session: ReportingSession) {
  console.log(`Sending farewell for token '${session.token}'`)
  await ingestApi.farewell(session.token)
}

export async function reportTelemetry(
  mock: MockedObject,
  telemetry: Telemetry
) {
  console.log(`Reporting telemetry for ${mock.uasId}`)
  await ingestApi.pushTelemetry(telemetry, mock.session?.token).catch((err) => {
    console.error(`Failed to report telemetry: ${err}`)
  })
}

export async function reportStatus(mock: MockedObject, status: DeviceStatus) {
  console.log(`Reporting status for ${mock.uasId}`)
  await ingestApi.pushStatus(status, mock.session?.token).catch((err) => {
    console.error(`Failed to report status: ${err}`)
  })
}

export async function reportPack(mock: MockedObject) {
  const telemetryCount = mock.storedTelemetry?.length ?? 0
  console.log(`Reporting update pack of ${telemetryCount} telemetry points for ${mock.uasId}`)

  await ingestApi.pushPack(
    {
      telemetry: mock.storedTelemetry ?? [],
      status: generateStatus(mock),
    },
    mock.session?.token
  )
}
