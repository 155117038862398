import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import React, { useContext } from 'react'
import { StateContext } from '../rootState'
import { MissionRequest } from '../types/mission'

interface NewMissionDialogProps {
  open: boolean
  onClose: () => void
  onStarted: (mission: MissionRequest) => void
}

export default function NewMissionDialog(props: NewMissionDialogProps) {
  const [rootState] = useContext(StateContext)

  function handleSubmit(ev: React.FormEvent<HTMLFormElement>) {
    ev.preventDefault()
    const data = Object.fromEntries(new FormData(ev.currentTarget))
    props.onStarted({
      mission: {
        type: 'random',
        takeoff: {
          latitude: rootState?.defaultTakeoff?.latitude ?? 50.1,
          longitude: rootState?.defaultTakeoff?.longitude ?? 14.4,
        },
        speed: 20,
        radius: 200,
      },
      uasId: data.uasId?.toString(),
    })
  }

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Start a new mocked mission</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="uasId"
            id="uasId"
            label="UAS ID"
            fullWidth
            variant="standard"
            defaultValue="AAXX0001"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button type="submit">Start</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
