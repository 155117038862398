import {
  AppBar,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Chip,
  IconButton,
} from '@mui/material'
import {
  Pause as PauseIcon,
  PlayArrow as PlayIcon,
  SkipNext as ForwardIcon,
  ArrowDropDown,
} from '@mui/icons-material'
import { useState, useContext } from 'react'
import { StateContext, Environment } from '../rootState'
import projectPackage from '../../package.json'

export default function MainAppBar(props: {
  isRunning: boolean
  onSingleStep: () => void
  onPauseToggle: () => void
}) {
  const [envMenuEl, setEnvMenuEl] = useState<null | HTMLElement>(null)
  const [rootState, rootDispatch] = useContext(StateContext)

  function switchEnvironment(envName: string) {
    if (Object.keys(rootState.mocks).length > 0) {
      console.error(
        'You must land or kill all of the mocks before' +
          ' changing the environment.'
      )
    } else {
      const env = Object.entries(Environment).find(
        ([k, v]) => k === envName
      )?.[1]
      if (env == null) return
      rootDispatch({ type: 'switch_env', env })
    }
    setEnvMenuEl(null)
  }

  const currEnv = rootState.environment.toString()
  const envCapitalized =
    currEnv[0].toUpperCase() + currEnv.substring(1).toLowerCase()

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Dronetag Mocker
        </Typography>
        {!props.isRunning ? (
          <IconButton size="large" color="inherit" onClick={props.onSingleStep}>
            <ForwardIcon />
          </IconButton>
        ) : null}
        <IconButton
          size="large"
          color="inherit"
          sx={{ mr: 1 }}
          onClick={props.onPauseToggle}
        >
          {props.isRunning ? <PauseIcon /> : <PlayIcon />}
        </IconButton>
        <Chip
          label={props.isRunning ? 'Running' : 'Paused'}
          color={props.isRunning ? 'success' : 'warning'}
          sx={{ mr: 2 }}
          size="small"
        />
        {projectPackage.options.allowEnvironmentSwitch ? (
          <Chip
            label={`${envCapitalized} environment`}
            icon={<ArrowDropDown />}
            color="info"
            variant="outlined"
            sx={{ mr: 1 }}
            size="small"
            onClick={(ev) => setEnvMenuEl(ev.currentTarget)}
          />
        ) : null}
      </Toolbar>
      <Menu
        id="menu-envselection"
        anchorEl={envMenuEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(envMenuEl)}
        onClose={() => setEnvMenuEl(null)}
      >
        {Object.keys(Environment).map((env) => {
          return (
            <MenuItem onClick={() => switchEnvironment(env)}>
              Change to {env}
            </MenuItem>
          )
        })}
      </Menu>
    </AppBar>
  )
}
