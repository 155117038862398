import React from 'react'
import { Console as ConsoleFeed, Hook, Unhook } from 'console-feed'
import { Message as ComponentMessage } from 'console-feed/lib/definitions/Component'
import { HookedConsole, Message } from 'console-feed/lib/definitions/Console'

export default function Console() {
  const [logs, setLogs] = React.useState<Message[]>([])

  // run once!
  React.useEffect(() => {
    Hook(
      window.console,
      (log) => setLogs((currLogs) => [...currLogs, log]),
      false
    )
    return () => {
      Unhook(window.console as HookedConsole)
    }
  }, [])

  React.useEffect(() => {
    const logger = document.getElementById('logger')

    if (logger == null) return

    if (logger.scrollTop + window.innerHeight * 0.4 >= logger.scrollHeight) {
      logger.scrollTo({
        top: logger.scrollHeight,
        behavior: 'smooth',
      })
    }
  }, [logs])

  return (
    <div
      id="logger"
      style={{
        height: '32vh',
        overflowY: 'scroll',
      }}
    >
      <ConsoleFeed logs={logs as ComponentMessage[]} variant="dark" />
    </div>
  )
}
