import React, { Dispatch, ReducerState } from 'react'
import { MissionRequest } from './types/mission'
import MockedObject, { MockMapFunction } from './types/mockedObject'
import ReportingSession from './types/reportingSession'
import Location from './types/location'
import { qne } from './convertors'
import projectPackage from '../package.json'
import { Telemetry } from '@dronetag/dronetag-sdk-live'

export enum Environment {
  production = 'production',
  staging = 'staging',
  local = 'local',
}

export type ReportingConfiguration = {
  simulationInterval: number
  telemetryInterval: number
  statusInterval: number
  type: 'default' | 'packs'
  aggregation: number
}

export interface RootState {
  environment: Environment
  mocks: { [uasId: string]: MockedObject }
  selectedMockId: string | null
  isRunning: boolean
  defaultTakeoff: Location
  apiKey: string | null
  reporting: ReportingConfiguration
}

export type RootStateAction =
  | {
      type: 'create_mock'
      request: MissionRequest
      session: ReportingSession | null
    }
  | { type: 'update_mock'; id: string; fn: MockMapFunction }
  | { type: 'remove_mock'; id: string }
  | { type: 'map_mocks'; fn: MockMapFunction }
  | { type: 'clear_mocks' }
  | { type: 'toggle_running' }
  | { type: 'select_mock'; id: string | null }
  | { type: 'switch_env'; env: Environment }
  | { type: 'update_default_takeoff'; location: Location }
  | { type: 'update_apikey'; key: string | null }
  | {
      type: 'update_reporting'
      config: ReportingConfiguration
    }
  | { type: 'increase_sent_telemetry'; id: string }
  | { type: 'increase_sent_status'; id: string }
  | { type: 'store_telemetry'; id: string; telemetry: Telemetry }
  | { type: 'clear_stored_telemetry'; id: string }

export function reducer(state: RootState, action: RootStateAction): RootState {
  switch (action.type) {
    case 'create_mock':
      return {
        ...state,
        mocks: {
          ...state.mocks,
          [action.request.uasId]: {
            isActive: action.session !== null,
            isBusy: false,
            uasId: action.request.uasId,
            type: 'aircraft',
            state: {
              location: action.request.mission.takeoff,
              velocity: { x: 0, y: 0, z: 0 },
              battery: 4,
              satellites: 3,
              rsrp: -100,
              cellid: 'X',
              pressure: qne,
            },
            mission: action.request.mission,
            session: action.session,
            stats: {
              sentStatusMsgs: 0,
              sentTelemetryMsgs: 0,
            },
          },
        },
      }
    case 'update_mock':
      return {
        ...state,
        mocks: {
          ...state.mocks,
          [action.id]: action.fn(state.mocks[action.id]),
        },
      }

    case 'remove_mock':
      return {
        ...state,
        mocks: Object.fromEntries(
          Object.entries(state.mocks).filter(([id, _]) => id !== action.id)
        ),
      }

    case 'map_mocks':
      return {
        ...state,
        mocks: Object.fromEntries(
          Object.entries(state.mocks).map(([uasId, mock]) => [
            uasId,
            action.fn(mock),
          ])
        ),
      }

    case 'clear_mocks':
      return { ...state, mocks: {} }

    case 'toggle_running':
      return { ...state, isRunning: !state.isRunning }

    case 'select_mock':
      return { ...state, selectedMockId: action.id }

    case 'switch_env':
      return { ...state, environment: action.env }

    case 'update_default_takeoff':
      return {
        ...state,
        defaultTakeoff: { ...state.defaultTakeoff, ...action.location },
      }

    case 'update_apikey':
      return { ...state, apiKey: action.key }

    case 'update_reporting':
      return {
        ...state,
        reporting: action.config,
      }

    case 'increase_sent_status':
      return stateWithUpdatedMock(state, {
        ...state.mocks[action.id],
        stats: {
          ...state.mocks[action.id].stats,
          sentStatusMsgs: state.mocks[action.id].stats.sentStatusMsgs + 1,
        },
      })

    case 'increase_sent_telemetry':
      return stateWithUpdatedMock(state, {
        ...state.mocks[action.id],
        stats: {
          ...state.mocks[action.id].stats,
          sentTelemetryMsgs: state.mocks[action.id].stats.sentTelemetryMsgs + 1,
        },
      })

    case 'store_telemetry':
      return stateWithUpdatedMock(state, {
        ...state.mocks[action.id],
        storedTelemetry: [
          ...(state.mocks[action.id].storedTelemetry ?? []),
          action.telemetry,
        ],
      })

    case 'clear_stored_telemetry':
      return stateWithUpdatedMock(state, {
        ...state.mocks[action.id],
        storedTelemetry: [],
      })
  }
}

const stateWithUpdatedMock: (
  state: RootState,
  mock: MockedObject
) => RootState = (state, mock) => ({
  ...state,
  mocks: {
    ...state.mocks,
    [mock.uasId]: mock,
  },
})

export const defaultState: RootState = {
  environment:
    (localStorage.getItem('env') as Environment) ??
    Object.entries(Environment).find(
      ([_, v]) => v === projectPackage.options.defaultEnvironment
    )?.[1] ??
    Environment.staging,
  mocks: {},
  isRunning: true,
  selectedMockId: null,
  defaultTakeoff: {
    latitude: 50.103997,
    longitude: 14.3884453,
    altitude: 0,
  },
  apiKey: null,
  reporting: {
    simulationInterval: 500,
    telemetryInterval: 2000,
    statusInterval: 15000,
    type: 'default',
    aggregation: 1,
  },
}

export const StateContext = React.createContext<
  [ReducerState<typeof reducer>, Dispatch<RootStateAction>]
>([defaultState, () => {}])
