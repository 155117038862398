import { ThemeOptions, createTheme } from '@mui/material/styles'
import { Shadows } from '@mui/material/styles/shadows'

function generateShadow(elevation: number) {
if (elevation === 0) return 'none'
    return `
      0px ${Math.floor(elevation/3)}px ${Math.floor(elevation/2)}px ${Math.floor(elevation/-4)}px #41434544,
      0px ${Math.floor(elevation/1.5)}px ${Math.floor(elevation)}px ${Math.floor(elevation/8)}px #41434544
    `
}

export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#00a0ff',
      light: '#39b8ff',
      dark: '#0078c2',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#6d67d1',
      light: '#8380da',
      dark: '#41399b',
    },
    info: {
      main: '#627079',
      light: '#7f898f',
      dark: '#485964',
    },
    divider: '#edf1f2',
  },
  typography: {
    fontFamily: '"Titillium Web", "Helvetica", "Arial", sans-serif',
  },
  spacing: 8,
  components: {
    MuiAppBar: {
      defaultProps: {
        color: 'default',
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 99,
          fontWeight: 600,
          textTransform: 'none',
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: 99,
        },
      },
    }
  },
  shape: {
    borderRadius: 4,
  },
  shadows: Array.from({length: 25}, (_, idx) => generateShadow(idx)) as Shadows,
}

const theme = createTheme(themeOptions)

export default theme
