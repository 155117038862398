import { Button, Card, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import React, { useContext } from 'react'
import { StateContext } from '../rootState'

interface MockerControlsProps {
  requireApiKey: boolean
}

const MockerControls: React.FC<MockerControlsProps> = ({
  children,
  requireApiKey = false,
}) => {
  const [rootState, rootDispatch] = useContext(StateContext)

  const handleKeySubmit = (ev: React.FormEvent) => {
    if (ev.target instanceof HTMLFormElement) {
      const formData = new FormData(ev.target)
      rootDispatch({
        type: 'update_apikey',
        key: formData.get('key')?.toString() || null,
      })
    }
    ev.preventDefault()
  }

  if (requireApiKey && rootState.apiKey == null) {
    return (
      <div className="api-key-form">
        <Card variant="outlined" sx={{ p: 3 }}>
          <Typography variant="h6">
            Authenticate the session using API key
          </Typography>
          <p>Use a Service API key with access to devices.</p>
          <form onSubmit={handleKeySubmit}>
            <Grid container spacing={4}>
              <Grid item xs={8}>
                <TextField name="key" label="API key" size="small" fullWidth />
              </Grid>
              <Grid item xs={4}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  fullWidth
                >
                  Authenticate
                </Button>
              </Grid>
            </Grid>
          </form>
        </Card>
      </div>
    )
  }

  return <>{children}</>
}

export default MockerControls
